import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Translations } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
  @ViewChild('fileUpload') fileUpload: FileUpload;

  @Output() uploadHandler = new EventEmitter<any>();

  @Input() label: string;
  @Input() auto: boolean;

  uploading: boolean;
  entityPath: string;

  constructor(private cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private route: ActivatedRoute,
    public readonly lang: Translations) {
      this.route.data.subscribe(routerData => {
        this.entityPath = routerData.page.path;
      });
    this.label = this.entityPath.includes("financier") ? this.translate.instant(this.lang.SHARED.FILES.addLogo) : this.translate.instant(this.lang.SHARED.FILES.addFile);

  }

  getLabel(): string {
    return this.label || this.translate.instant(this.lang.SHARED.FILES.addFile);
  }

  upload(event) {
    this.uploading = true;
    this.uploadHandler.emit(event);
  }

  clear() {
    this.uploading = false;
    this.fileUpload.clear();
    this.fileUpload.onBlur();
    this.cdRef.markForCheck();
  }
}
